import Menu from "./menu";
import HeaderAnimation from "./header-animation";
import jQuery from "jquery";


require("!file-loader?name=images/favicon.png!../images/favicon.png");
require("!file-loader?name=images/helphouse_cover.png!../images/helphouse_cover.png");
require("!file-loader?name=images/helphouse_cover@2x.png!../images/helphouse_cover@2x.png");
require("!file-loader?name=images/helphouse_cover@3x.png!../images/helphouse_cover@3x.png");
require("!file-loader?name=images/helphouse_logo.png!../images/helphouse_logo.png");
require("!file-loader?name=images/logo.svg!../images/logo.svg");
require("!file-loader?name=images/news-list-placeholder.png!../images/news-list-placeholder.png");


jQuery(function($) {
  new Menu();
  new HeaderAnimation();

  $(".card").each(function () {
    $(this).parent().addClass("has-card");

    $(this).parent().parent().addClass("has-card");
  });

  let selectedClass = "";
  $(".filter-button").on("click", function () {
    $(this).addClass("active").siblings().removeClass("active");
    selectedClass = $(this).attr("data-filter");
    $(".case-list").fadeTo(100, 0.1);
    $(".case-list > article").not("." + selectedClass).fadeOut().removeClass("scale-anm");
    setTimeout(function () {
      $("." + selectedClass).fadeIn().addClass("scale-anm");
      $(".case-list").fadeTo(300, 1);
    }, 300);
  });

  $("#case-switch").typewriter({
    strings: (function() { let _L = []; $("#typed-strings .switch-text").each(function() {
      _L.push($(this).text() + "."); }); return _L; })(),
  });
});


(function($) {
  $.fn.typewriter = function (opts) {
    let default_options = {
      strings: [],
      delay: 0,
      typing_speed: 100,
      backspace_speed: 40,
      word_show: 1500,
      word_pause: 0,
    };

    let options = Object.assign({}, default_options, opts);

    if($(this).lengt > 0 && options.strings.length === 0) {
      throw new Error("Strings are missing!");
    }

    $(this).each(function () {
      let container = $(this);

      function _exec(str_index, delay) {
        setTimeout(function () {
          let string = options.strings[str_index];

          function _typing(length) {
            container.text(string.substr(0, length));

            if(length === string.length) {
              setTimeout(function() {
                _backspace(string.length);
              }, options.word_show);
            }
            else {
              let offset = (Math.random() * options.typing_speed * 2) - options.typing_speed/2;
              let typing_speed = options.typing_speed + offset;

              setTimeout(function() {
                _typing(length + 1);
              }, typing_speed);
            }
          }

          function _backspace(length) {
            container.text(string.substr(0, length));

            if(length === 0) {
              let next_str_index = typeof options.strings[str_index + 1] === "undefined" ?
                0 :
                str_index + 1;

              _exec(next_str_index, options.word_pause);
            }
            else {
              setTimeout(function () {
                _backspace(length - 1);
              }, options.backspace_speed);
            }
          }

          _typing(0);
        }, delay);
      }

      _exec(0, options.delay);
    });
  };
})(jQuery);
