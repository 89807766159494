import $ from "jquery";


export default class Menu {
  constructor() {
    this.isActive = false;

    $(".menu-icon").each((i, el) => {
      $(el).click((e) => {
        e.preventDefault();
        this.handleMenu();
      });
    });

    $(window).resize(() => {
      this.detectWindowSize();
    });
  }

  detectWindowSize() {
    if($(window).width() >= 768) {
      this.closeMenu();
    }
  }

  handleMenu() {
    if(!this.isActive) {
      this.openMenu();
      return;
    }

    if(this.isActive) {
      this.closeMenu();
      return;
    }
  }

  openMenu() {
    this.isActive = true;

    $(".off-page-menu").addClass("is-active");
    $("body").addClass("no-scroll");
    $(".overlay").addClass("is-active");
  }

  closeMenu() {
    this.isActive = false;

    $(".off-page-menu").removeClass("is-active");
    $("body").removeClass("no-scroll");
    $(".overlay").removeClass("is-active");
  }
}
