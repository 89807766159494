import $ from "jquery";


export default class HeaderAnimation {
  constructor() {
    this.offset = $(window).scrollTop();
    this.header = $(".header");

    this.calcOffset();
    this.animate();
  }

  calcOffset() {
    $(window).scroll(() => {
      this.offset = $(window).scrollTop();
    });
  }

  animate() {
    $(window).scroll(() => {
      if(this.offset > 200) {
        this.header.addClass("header-fixed");
      } else {
        this.header.removeClass("header-fixed");
      }
    });
  }
}
